import React from "react";
import { Grid, Typography } from "@mui/material";

import { TrainingDocument, DatabricksCard, TrainingCard } from "./cards";

const BrickAndTrainingSection = (props) => {
  return (
    <Grid container xs={12} flexDirection={"column"} spacing={4}>
      {props.showDataBrick && (
        <Grid item container xs={12} spacing={2}>
       
          <Grid
            item
            textAlign={"left"}
            xs={12}
            className="BrickAndTrainingSectionHeader"
          >
            <Typography variant="h4" >
              Databricks
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <a href={props.trainingLink} target="_blank" >
             <DatabricksCard />
            </a>
          </Grid>

        </Grid>
        
      )}
      {props.showTrainingDocs && (
        <Grid item container xs={12} spacing={2}>
         
          <Grid
            item
            textAlign={"left"}
            xs={12}
            className="BrickAndTrainingSectionHeader"
          >
            <Typography variant="h4" >
               Training Documents
            </Typography>
            
          </Grid>
          <Grid item xs={12}>
            <a href={props.databricksLink} target="_blank" >
              <TrainingCard />
            </a>
          </Grid>
       
        </Grid>
      )}
    </Grid>
  );
};

export default BrickAndTrainingSection;
