import React,{useEffect} from "react"
import DashboardContent from "./content/dashboard"
import { ConfigContext } from "./config/config"

export default function Dashboard() {
  const configDetails = React.useContext(ConfigContext)

  useEffect((()=>{
    document.title = configDetails.data.map((item) => {return item.tabtitle})
  }))

  return (
    <>
      <DashboardContent />
    </>
    
  )
}
