import React, {useEffect} from "react"
import { Grid } from "@mui/material"
import Header from "./header"
import Footer from "./footer"
import Title from "./title"
import { ConfigContext } from "../config/config"

 const Layout = ({children, props}) => {
  const configDetails = React.useContext(ConfigContext)

  useEffect(() => {

     // const root = document.documentElement;
  //   // root?.style.setProperty(
  //   //   "--headerContainerBackgroundColor",
  //   //   "#FFF"
  //   // )
   
    document.documentElement.style.setProperty('--app-backgroundColor', configDetails.data[0].theme.background)
    document.documentElement.style.setProperty('--app-headerBackgroundColor', configDetails.data[0].theme.headerbackgroundColor)
    document.documentElement.style.setProperty('--app-footerBackgroundColor', configDetails.data[0].theme.footerbackgroundColor)
    document.documentElement.style.setProperty('--app-primary', configDetails.data[0].theme.primary)
    document.documentElement.style.setProperty('--app-gradientBackgroundColor', configDetails.data[0].theme.cardGradientBackgroundColor)
    document.documentElement.style.setProperty('--app-cardFontColor', configDetails.data[0].theme.cardFontColor)
    document.documentElement.style.setProperty('--app-fontColor', configDetails.data[0].theme.fontColor)
    document.documentElement.style.setProperty('--app-fontColor', configDetails.data[0].theme.fontColor)
    document.documentElement.style.setProperty('--app-h1', configDetails.data[0].theme.h1)
    document.documentElement.style.setProperty('--app-h2', configDetails.data[0].theme.h2)
    document.documentElement.style.setProperty('--app-h3', configDetails.data[0].theme.h3)
    document.documentElement.style.setProperty('--app-h4', configDetails.data[0].theme.h4)
    document.documentElement.style.setProperty('--app-h5', configDetails.data[0].theme.h5)
    document.documentElement.style.setProperty('--app-pageTitle', configDetails.data[0].theme.pageTitle)
    document.documentElement.style.setProperty('--app-menuIcon', configDetails.data[0].theme.menuIcon)
    document.documentElement.style.setProperty('--app-body1', configDetails.data[0].theme.paragraph)

    sessionStorage.setItem('icons', configDetails.data[0].theme.icons)

  }, [configDetails])

  return (
      <Grid container item {...props} className="fadePanel" xs={12} >
      <Grid item xs={12} sm={12} md={12} lg={12} className="headerContainer">
        <Header />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} className="titleContainer" >
        <Title />
      </Grid>

      <Grid
        item
        xs={11}
        sm={11}
        md={12}
        lg={12}
        className="contentContainer fadePanel"

      >

        {children}
      </Grid>

      <Grid item xs={12} >
        <div className="spacer20" />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} className="footerContainer">
        <Footer />
      </Grid>

    </Grid>
  )
}

export default Layout

// export default ({ children, ...props } = {}) => (
//   <Grid container item {...props} className="fadePanel" xs={12} >
//     <Grid item xs={12} sm={12} md={12} lg={12} className="headerContainer">
//       <Header />
//     </Grid>

//     <Grid item xs={12} sm={12} md={12} lg={12} className="titleContainer" >
//       <Title />
//     </Grid>

//     <Grid
//       item
//       xs={11}
//       sm={11}
//       md={12}
//       lg={12}
//       className="contentContainer fadePanel"
//     >

//       {children}
//     </Grid>

//     <Grid item xs={12} >
//       <div className="spacer20" />
//     </Grid>

//     <Grid item xs={12} sm={12} md={12} lg={12} className="footerContainer">
//       <Footer />
//     </Grid>

//   </Grid>
// )
