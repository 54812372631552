import * as React from 'react';
import {
    List,
    ListItem,
    ListItemText,
    Typography,
    Avatar,
    Button,
    Grid
} from '@mui/material/'
import {TRIANGLE_ICON} from "../assets/icons/IconList"

 function AlignItemsList(props) {
  return (

    <List >
        {
            props.list.map(item=>{
            return <ListItem alignItems="flex-start">
         
                <ListItemText
                    primary={<Typography  ><span style={{color: '#7287e5'}} ><TRIANGLE_ICON /></span> &nbsp; <span style={{color: props.color, fontSize: '18px'}}>{item}</span></Typography>}
                
                />
            </ListItem>
            })
        }
      
    
    </List>
  )
}

function SearchResultsList(props) {
    const truncateFileName = (str) => {
      str = JSON.stringify(str);
      return str.length > 10 ? '"' + "..." + str.substring(1, 150) + '..."' : str;
    };
  
    const truncateTitle = (str) => {
      return str.length > 100 ? str.substring(0, 90).trim() + "..." : str.trim();
    };
  
    function roundScore() {
      let percentage = (Math.round(props.Score * 100) / 100) * 100;
  
      return Math.floor(percentage) + "%";
    }
  
    return (
      <List sx={{ width: "100%"  }} className="listContainer fadePanel" id="listStyle">
      
        <ListItem alignItems="flex-start">
          <Typography
            variant="body1"
            style={{
              color: "#3097e0",
              fontSize: "1.1em",
              marginLeft: "15px",
              marginTop: "5px",
            }}
          >
            <b>Download:</b>
          </Typography>
  
          <ListItemText
            primary={
              <a
                href={
                  "https://centcomservices.ais.saicds.com:8080" +
                  props.Data.Meta.SourceFile
                }
                target="_blank"
                style={{
                  color: "#fff",
                  marginLeft: "35px",
                  fontSize: "1.2em",
                  textDecoration: 'none'
                }}
              >
                {truncateTitle(props.FileName)}
              
              </a>
            }
          />
        </ListItem>
  
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <span>
                <b
                  style={{
                    color: "#3097e0",
                    fontSize: "1.1em",
                    marginLeft: "15px",
                    marginTop: "1px",
                  }}
                >
                  Confidence:
                </b>{" "}
                <b
                  style={{
                    fontSize: "1.3em",
                    marginLeft: "20px",
                    marginTop: "1px",
                    color: 'aqua'
                
                  }}
                >
                  {roundScore()}
                </b>
              </span>
            }
            secondary={
              <React.Fragment>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={
                        <b
                    style={{
                      color: "#3097e0",
                      fontSize: "1.1em",
                      marginLeft: "0px",
                      marginTop: "1px",
                    }}
                  >
                    Context:
                  </b>
                      }
                     
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <ListItemText
                       primary={
                        <span
                        style={{
                          fontSize: "1.1em",
                          marginTop: "1px",
                          color: "#fff",
                         textAlign: 'left'
                        }}
                      >
                        {truncateFileName(props.Context.replace(/\n/g, " "))}
                      </span>
                      }
                    />
                  </ListItem>
                </List>

              </React.Fragment>
            }
          />



        </ListItem>
          
      </List>
    )
  }

function FolderList(props) {
    return (
        <Grid container xs={12} className="adminList" >
        

          <Grid item xs={12} >
            <List>
            
              <ListItem >
          
               <ListItemText primary={<Typography style={{fontSize: '25px', color: '#d7deff'}}>{props.title}</Typography>} secondary={<Typography style={{fontSize: '17px', color: '#7486d5'}}>{props.subTitle}</Typography>} />
          
              </ListItem>

            </List>
   
          </Grid>

          <Grid item xs={12} style={{ margin: '0 auto', textAlign: 'center'}} >
          <Button 
          variant='contained' 
          style={{width: '75%',height: '50px',}}
          sx={{
            background: '#3f58c6',
            ':hover': {
              bgcolor: '#8a9ffd', 
              color: 'white',
            },
          }}
          
          >{props.buttonLabel}</Button>
          </Grid>
      </Grid>

    )
  }

  
  export {
    SearchResultsList,
    AlignItemsList,
    FolderList
  }
