import React, {useEffect} from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { StyledEngineProvider } from "@mui/material/styles"
import AppWrapper from "./template/appTemplate"
import "./assets/sheets/main.scss"
import Dashboard from "./Dashboard"
import Admin from "./Admin"
import DataCatalog from "./DataCatalog"
import Tools from './Tools'
import NatLangSearch from "./NaturalLangSearch"
import Governance from "./Governance"
import DataUpload from "./DataUpload"
import {
  ConfigProvider
} from "./config/config"
import DemoListPage from "./content/ModelRegistry/Demo List/DemoListPage"
import { DemoProvider } from './content/ModelRegistry/DemoContext'

function App() {

  return (
    <StyledEngineProvider injectFirst>
      <DemoProvider >
      <div className="appContainer">
        <ConfigProvider>
        <AppWrapper>
          <BrowserRouter>
            <Routes>
              <Route path="/" exact element={<Dashboard />} />
              <Route path="/admin" exact element={<Admin />} />
              <Route path="/modelRegistry" element={<DemoListPage />} />
              <Route path="/dataCatalog"  element={<DataCatalog />} />
              <Route path="/tools" element={<Tools />} />
              <Route path="/natLangSearch"  element={<NatLangSearch />} />
              <Route path="/governance"  element={<Governance />} />
              <Route path="/dataUpload"  element={<DataUpload />} />
            </Routes>
          </BrowserRouter>
        </AppWrapper>
        </ConfigProvider>
      </div>
      </DemoProvider>
    </StyledEngineProvider>
  )
}

export default App
