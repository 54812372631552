import * as React from "react";
import {
  Card,
  Chip,
  CardContent,
  Grid,
  Typography,
  IconButton,
  CardActionArea,
  CardMedia,
  Collapse,
  Button,
  List,
  ListItem,
  FormGroup,
  Switch,
  FormControlLabel,
  ListItemText,
  Box,
  TextField,
  ListItemAvatar
} from "@mui/material"
import { styled } from "@mui/material/styles"
import {AlignItemsList, FolderList} from "../list"
import Tooltip from "../toolTip"
import {
  SETTING_ICON
} from "../../assets/icons/IconList"


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}))
function SettingsCard(props) {



  const [checked, setChecked] = React.useState( sessionStorage.getItem('view'))


  return (
    <Grid item xs={12} className="settingCardContainer" >
     
    <Card   >
 
      <CardContent  className="content" >
        <Grid container xs={12} >

         <Grid item xs={6}>
            <Typography variant="body1"  >
              
              Theme Settings
        
            </Typography>
            <div className="spacer20" />
         </Grid>

     


          <Grid item xs={10} className="marginCenter" >
            
            <FormGroup>
                <Card className="settingCard" ><CardContent><FormControlLabel control={<Switch style={{color: '#F6FCFF'}} defaultChecked />} label={<Typography style={{color: '#F6FCFF'}}>
                 Dark Theme </Typography>} /><SETTING_ICON /></CardContent></Card>
                 <div className="spacer20" />
                 <Card className="settingCard" ><CardContent><FormControlLabel control={<Switch />} label={<Typography style={{color: '#F6FCFF'}}>Light Theme</Typography>} /><SETTING_ICON /></CardContent></Card>
            </FormGroup>
          </Grid>

        </Grid>    
    
      </CardContent>
     
    </Card>
    </Grid>
  )
}



export { 
  SettingsCard
}
