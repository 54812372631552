import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: '12px',
    border: '1px solid #dadde9',
  },
}));

export default function CustomizedTooltips(props) {
  return (
    <div>

      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">{props.message}</Typography>
          
          </React.Fragment>
        }
      >
            <Button
                href={props.link}
                target='_blank'
                variant='contained'
                
                sx={{
                  padding: '15px',
                  ':hover': {
                    bgcolor: '#fff',
                    color: '#006bb6',
                  },
                }}
              >
                 
                {props.label}
              </Button>
      </HtmlTooltip>
    </div>
  )
}
