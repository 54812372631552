import React,{useEffect} from "react"
import { Grid } from "@mui/material/"
import DataUpload from "./content/dataUpload"
import { ConfigContext } from "./config/config"

export default function NLPupload() {

  const configDetails = React.useContext(ConfigContext)

  useEffect((()=>{
    document.title = configDetails.data.map((item) => {return item.tabtitle})
  }))

  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      spacing={3}
      className="searchContainer"
    >
  
      <Grid item xs={12} className="marginCenter">
        <DataUpload />
      </Grid>

    </Grid>
  )
}
