import React from "react"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import SearchIcon from "@mui/icons-material/Search"
import Menu from "@mui/icons-material/Menu"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import UploadIcon from "@mui/icons-material/Upload"
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import SummarizeIcon from '@mui/icons-material/Summarize'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import DataUsageIcon from '@mui/icons-material/DataUsage'
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import InsightsIcon from '@mui/icons-material/Insights'
import CreateIcon from '@mui/icons-material/Create'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import OtherHousesIcon from '@mui/icons-material/Home'
import FilterListIcon from '@mui/icons-material/FilterList'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ArticleIcon from '@mui/icons-material/Article'
import AppsIcon from '@mui/icons-material/Apps'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import SettingsIcon from '@mui/icons-material/Settings'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

const iconColor = sessionStorage.getItem('icons')

const ProfileIcon = (props) => {
  return <AccountCircleIcon style={{ fontSize: props.fontSize, color: props.color }} />;
}

const InsightIcon = () => {
  return <InsightsIcon style={{ fontSize: "30px", color: "#aafbf2" }} />;
}

const SettingIcon = () => {
  return <SettingsIcon style={{ fontSize: "40px", color: "#ffffff", float: 'right' }} />;
}

const AdminIcon = () => {
  return <AdminPanelSettingsIcon style={{ fontSize: "30px", color: "#01518B" }} />;
}

const AppIcon = (props) => {
  return <AppsIcon style={{ fontSize: "35px", color: props.color}} />
}

const DocumentIcon = (props) => {
  return <ArticleIcon style={{ fontSize: "50px", color: iconColor }} />;
}

const SubmitIcon = () => {
  return <KeyboardArrowUpIcon style={{ fontSize: "30px", color: "#fff" }} />;
}

const UserIcon = () => {
  return <PersonOutlineIcon style={{ fontSize: "35px", color: "#aafbf2" }} />;
}

const FilterIcon = (props) => {
  return <FilterListIcon style={{ fontSize: "35px", color: props.color }} />;
}

const HomeIcon = () => {
  return <OtherHousesIcon style={{ fontSize: "30px", color: "#aafbf2" }} />;
}

const EditIcon = (props) => {
  return <CreateIcon style={{ fontSize: "25px", color: props.color }} />;
}

const DownloadIcon = () => {
  return <CloudUploadIcon style={{ fontSize: "150px", color: "#fff" }} />;
}

const PhotoIcon = () => {
  return <AddPhotoAlternateIcon style={{ fontSize: "125px", color: "#006bb6" }} />;
}

const TriangleIcon = () => {
  return <ChangeHistoryIcon style={{ fontSize: "20px", color: "#506be7" }} />;
}

const SummaryIcon = () => {
  return <SummarizeIcon style={{ fontSize: "30px", color: "#2f388e" }} />;
}

const MetaDataIcon = () => {
  return <DataUsageIcon style={{ fontSize: "30px", color: "#2f388e" }} />;
}

const EntityIcon = () => {
  return <PermIdentityIcon style={{ fontSize: "30px", color: "#2f388e" }} />;
}

const RightArrowIcon = () => {
  return <ArrowCircleRightIcon style={{ fontSize: "25px", color: "#2f388e"}} />;
};

const LeftArrowIcon = () => {
  return <ArrowCircleLeftIcon style={{ fontSize: "25px", color: "#c144e3"}} />;
};

const CheckMark = () => {
  return <CheckCircleIcon style={{ fontSize: "25px", color: "#fff" }} />;
};
const Upload = () => {
  return <UploadIcon style={{ fontSize: "60px", color: "#bac7fe" }} />;
};
const Cancel = () => {
  return <CancelIcon style={{ fontSize: "25px", color: "#C06464" }} />;
};
const Search = (props) => {
 

  return (
    <SearchIcon style={{ fontSize: '40px', color: iconColor }} />
  );
};
const MenuIcon = (props) => {
  return <Menu style={{ fontSize: props.fontSize, color: props.color }} />;
};
const MoreVerticalIcon = (props) => {
  return (
    <MoreVertIcon
      style={{ fontSize: props.iconSize, color: props.iconColor }}
    />
  );
};
export const Icon = ({ component, style, ...props }) =>
  component({
    style: {
      width: "33px",
      height: "33px",
      borderRadius: "10px",
      textAlign: "center",
      padding: "5px",
      boxShadow: "#819dadff 1px 2px 4px",
      ...style,
    },
    ...props,
  });
export const DOWNLOAD_ICON = (props) =>
  Icon({ component: DownloadIcon, ...props });
export const SEARCH_ICON = (props) => Icon({ component: Search, ...props });
export const MENU_ICON = (props) => Icon({ component: MenuIcon, ...props });
export const MORE_VERTICAL_ICON = (props) =>
  Icon({ component: MoreVerticalIcon, ...props });
export const UPLOAD_ICON = (props) => Icon({ component: Upload, ...props });
export const CANCEL_ICON = (props) => Icon({ component: Cancel, ...props });
export const CHECK_MARK_ICON = (props) => Icon({ component: CheckMark, ...props });
export const RIGHT_ARROW_ICON = (props) => Icon({ component: RightArrowIcon, ...props });
export const LEFT_ARROW_ICON = (props) => Icon({ component: LeftArrowIcon, ...props })
export const SUMMARY_ICON = (props) => Icon({ component: SummaryIcon, ...props })
export const ENTITY_ICON = (props) => Icon({ component: EntityIcon, ...props })
export const META_DATA_ICON = (props) => Icon({ component: MetaDataIcon, ...props })
export const TRIANGLE_ICON = (props) => Icon({ component: TriangleIcon, ...props })
export const PHOTO_ICON = (props) => Icon({ component: PhotoIcon, ...props })
export const INSIGHT_ICON = (props) => Icon({ component: InsightIcon, ...props })
export const CREATE_ICON = (props) => Icon({ component: EditIcon, ...props })
export const USER_ICON = (props) => Icon({ component: UserIcon, ...props })
export const HOME_ICON = (props) => Icon({ component: HomeIcon, ...props })
export const FILTER_ICON = (props) => Icon({ component: FilterIcon, ...props })
export const SUBMIT_ICON = (props) => Icon({ component: SubmitIcon, ...props })
export const DOCUMENT_ICON = (props) => Icon({ component: DocumentIcon, ...props })
export const APP_ICON = (props) => Icon({ component: AppIcon, ...props })
export const ADMIN_ICON = (props) => Icon({ component: AdminIcon, ...props })
export const SETTING_ICON = (props) => Icon({ component: SettingIcon, ...props })
export const PROFILE_ICON = (props) => Icon({ component: ProfileIcon, ...props })