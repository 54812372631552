import React, { useEffect } from "react"
import {
  Grid,
  ListItem,
  TextField,
  List,
  ListItemText,
  Collapse,
  IconButton,
  Chip,
  Button,
  Typography
} from "@mui/material/"
import {
  SEARCH_ICON,
  FILTER_ICON
} from "../assets/icons/IconList"

import {DataCatalogCard} from "../components/dataCatalog/cards"
import axios from "axios"

export default function DataCatalog() {

  useEffect(() => {
    getDataCatalogList()
  }, [])
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [dataFiles, setDataFiles] = React.useState([])

  const getDataCatalogList = () => {

      let data = JSON.stringify({
        "limit" : 50,
        "next": "54b834c7-f678-4c53-87a1-98bc06c1940a"
      })

      let config = {
        method: 'post',
        url: `${BASE_URL}/record/list`,
        headers: { 
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.rs1-a0IbdNh4p73jLHQ6xVFUBn2Xn2CLJHA0fbGQ6ko', 
          'Content-Type': 'application/json'
        },
        data: data
      }

      axios.request(config)
      .then(function (response) {
        console.log(response.data.Items)
        setDataFiles(response.data.Items)

      })
      .catch(function (error) {
        console.log(error);
      })

    }
 

  return (
   
    <div className="dataCatalogContainer" >

      <Grid container xs={12}  >
        <Grid item xs={12}  >
          <List style={{paddingLeft: '25px', marginTop: '10px'}} >
     
            <ListItem>
          
              <ListItemText
                primary={<div className="pageDescription" >Uploaded datasets and their descriptions, searchable by type</div>}
              />
            </ListItem>
          </List>
          </Grid>
        </Grid>

      <Grid container xs={11}  className="dataCatalogContainer" >

        <Grid container xs={12} spacing={3} >

            <Grid item xs={2}  >
           <div className="spacer20" />
                <Button
                  href={"/dataUpload"}
                >
                  Upload Dataset

                </Button>
              
              <div className="spacer30" />
           
              <Button
                  href={"/"}
                >
                  Link to Dataset

                </Button>
            
            </Grid>

            <Grid item xs={10}  >
              <Grid item xs={12}  >
              <Grid
              container
              xs={12}
              className="searchBox"
            >
              
              <Grid item xs={1} style={{margin: '10px 0 0 0',paddingLeft: '5px'}} >
                <SEARCH_ICON color={"#999"}   />
              </Grid>
              <Grid item xs={10} >
            
                <TextField
                  className="input"
                  sx={{
                    "& fieldset": { border: "none" },
                    width: "75%",
                  }}
                  autoFocus
                  placeholder={"Search Data Catalog "}
                  name="questionSearch"
                />
              </Grid>

              <Grid item xs={1} style={{marginTop: '3px'}}>
                <IconButton
                  type="submit"
                  id="searchBtn"
                  variant="contained"
                  className="searchButton"
                >
                  <FILTER_ICON
                    color={"#ffffff"}
                  />
                </IconButton>
            
                 </Grid>
              </Grid>

                <div className="spacer30" />

                <Grid item xs={12}  >
                  <Chip label="CPG" variant="contained"  />
                  &nbsp;&nbsp;
                  <Chip label="Strategy" variant="outlined"  />
                  &nbsp;&nbsp;
                  <Chip label="News" variant="outlined" />
                  &nbsp;&nbsp;
                  <Chip label="Data" variant="outlined"  />
                  &nbsp;&nbsp;
                  <Chip label="Advana" variant="outlined" />
                </Grid>


              </Grid>
              <div className="spacer20" />
              <div className="spacer30" />
              <Grid container xs={12} spacing={2}  >
                <Grid item xs={10} className="centerAlign" >
                <div className="spacer30" />
                <div className="spacer30" />
                <div className="spacer30" />
                <div className="spacer30" />
                  <Typography className="pageStamp" >
                      No datasets currently published
                  </Typography>
                </Grid>
     
                {/* {
                  dataFiles.map((item)=>{
                
                  return <Grid item xs={6}  >
                  
                           <DataCatalogCard 
                                  date={item.createdAt}
                                  updatedDate={item.updatedAt}
                                  requestedBy={item.requestedBy}
                                  source={item.meta}
                                  name={item.meta}
                                  owner={item.meta}
                                  desc={item.meta}
                                  email={"JohnDoe@example.com"}
                                  team={"Data Analytics"}
                                  type={"ADHOC"}
                                  file={"/sample/sample_file.xlsx"}
                                  color={"#333"}
                                  backgroundColor={"#d4fffa"}
                                />
                   
                  
                  </Grid>
                     })
                    } */}

      

                
              </Grid>
            </Grid>

        </Grid>

        
      </Grid>
    </div>

  )
}
