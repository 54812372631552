import React from "react"
import { Grid } from "@mui/material"
import Tenjin from "../assets/images/SAIC-TENJIN-Name.png"
import MenuComponent from "./menu"
import { ConfigContext } from "../config/config"

const Header = () => {

  const configDetails = React.useContext(ConfigContext)

  return (

    <div>
      <Grid container xs={12} sm={12} md={12} lg={12}>
   
      {configDetails.data.map((item) => {
        return <Grid container xs={12} sm={12} md={12} lg={12} >
         
          <Grid item xs={2} className="fadePanel" >
            
            <a href={"/"}>
              <img src={Tenjin} alt="SAIC Logo" />
            </a>
      
            
          </Grid>

          <Grid xs={10} className="rightAlign" >
            <MenuComponent />
          </Grid>

        </Grid>

      })}
      
      
      </Grid>
    </div>
  )
}

export default Header
