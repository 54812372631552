import React, { useEffect } from "react";
import { Grid } from "@mui/material/";
import {
  DashboardCard,
  TrainingDocument,
  DatabricksCard,
  FeaturesCard,
} from "../components/dashboard/cards";

import SearchSection from "../components/dashboard/searchSection";
import BrickAndTrainingSection from "../components/dashboard/brickAndTrainingSection";
import { ToolsGrid, ToolsList } from "../components/dashboard/tools";

import { ConfigContext } from "../config/config"

export default function Dashboard() {
  const configDetails = React.useContext(ConfigContext);

  return (
    <Grid container xs={11} className="dashboardContainer">
      {configDetails.data.map((item) => {
        const showSearchSection =
          item.dashboard.components.datacatalog ||
          item.dashboard.components.nlpsearch ||
          item.dashboard.components.modelregistry;

        const showDatabricksAndTrainingSection =
          item.dashboard.components.databricks ||
          item.dashboard.components.training;


        return (
          <>
            <Grid item container xs={12} spacing={3}>
              <Grid item xs={item.dashboard.components.features ? 6 : 6}>
                {item.dashboard.components.tenjin && <DashboardCard link={item.tenjinlink} />}
              </Grid>
              <Grid item xs={item.dashboard.components.tenjin ? 6 : 8}>
                {item.dashboard.components.features && <FeaturesCard />}
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={3} marginTop={4}>
              {showSearchSection && (
                <Grid item xs={showDatabricksAndTrainingSection ? 8 : 12}>
                  <SearchSection
                    showDataCatalog={item.dashboard.components.datacatalog}
                    showModelRepository={
                      item.dashboard.components.modelregistry
                    }
                    showNlp={item.dashboard.components.nlpsearch}
                  />
                </Grid>
              )}
              {showDatabricksAndTrainingSection && (
                <Grid item xs={showSearchSection ? 4 : 6}>
                  <BrickAndTrainingSection
                    showDataBrick={item.dashboard.components.databricks}
                    showTrainingDocs={item.dashboard.components.training}
                    trainingLink={item.traininglink}
                    databricksLink={item.databrickslink}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item container xs={12} spacing={3} marginTop={4} className="marginCenter">
        
              <Grid item xs={item.dashboard.components.features ? 8 : 12}>
                {item.dashboard.components.tools && <ToolsGrid tools={item.tools} />}
              </Grid>
              <Grid item xs={item.dashboard.components.tenjin ? 4 : 12} >
                <div className="spacer20" />
                <div className="divider" />
                <div className="spacer20" />
                {item.dashboard.components.links && <ToolsList />}
              </Grid>

            </Grid>
          </>
        )
      })}
    </Grid>
  )
}
