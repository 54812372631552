import React, { createContext, useState } from "react"
import ConfigSheet from "./config.json"

const ConfigContext = createContext(undefined);
const ConfigDispatchContext = createContext(undefined);

function ConfigProvider({ children }) {
  const [configDetails, setConfigDetails] = useState({
    data: [ConfigSheet]
  })

  return (
    <ConfigContext.Provider value={configDetails}>
      <ConfigDispatchContext.Provider value={setConfigDetails}>
        {children}
      </ConfigDispatchContext.Provider>
    </ConfigContext.Provider>
  )
}

export { ConfigProvider, ConfigContext, ConfigDispatchContext };