import * as React from "react";
import {
  Card,
  Chip,
  CardContent,
  Grid,
  Typography,
  IconButton,
  CardActionArea,
  CardMedia,
  Collapse,
  Button,
  List,
  ListItem,
  FormGroup,
  Switch,
  FormControlLabel,
  ListItemText,
  Box,
  TextField,
  ListItemAvatar,
} from "@mui/material";
import { styled } from "@mui/material/styles"
import Tooltip from "../toolTip";
import {  DOCUMENT_ICON } from "../../assets/icons/IconList";


import Databricks from "../../assets/images/toolsPage/Databricks_square.png"

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function DashboardCard(props) {
  return (
    <Grid item xs={12} className="dashboardCardContainer">
      
        <Card>
          <CardContent sx={{ flex: "1 0 auto" }} className="content">
            <Grid container xs={12}>
              <Grid container xs={8}>
                <Grid item xs={12}>
                  <List style={{ marginTop: "-20px" }}>
                    <ListItem>
                      <ListItemText
                        primary={
                          <span >
                            {props.title}
                            <br />
                          </span>
                        }
                        secondary={
                          <Typography variant="h5" >
                            Data Wrangling, AI/ML & Analytics
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>

                <Grid item xs={10} className="marginCenter">
                 <br/>
                 <br/>
                  <Tooltip
                    label={"Tenjin Design Studio"}
                    message={
                      "Click to visit our main Tenjin site, where you can build AI/Ml models."
                    }
                    link={"https://dataiku.appdev.saicds.com/home/"}
                    buttonColor={props.buttonColor}
                  />
                </Grid>
              </Grid>

              <Grid item xs={4} className="marginCenter">
                
              </Grid>
            </Grid>
          </CardContent>
        </Card>
    
    </Grid>
  )
}

const FeaturesCard = (props) => {
  return (
    <Grid item xs={12} className="dashboardCardContainer">
      <a href={props.link}>
        <Card>
          <CardContent sx={{ flex: "1 0 auto" }} className="content">
            <Grid container xs={12} spacing={4}>
              <Grid item xs={12}>
                <Typography
                  className="accent"
                  textTransform="uppercase"
                  fontWeight={500}
                  lineHeight={1.8}
                >
               
                  Insights & Workflow
                </Typography>
                <Typography
                  fontSize={22}
                  variant="h3"
                  fontWeight={500}
                  lineHeight={1.8}
                >
                
                  Fully Supported AI-Enhanced Applications & Quarterly Reviewed
                  Financials
                </Typography>
                <Typography className="accent" >
         
                  App Library - Tools - WebApps
                </Typography>
              </Grid>
              <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                <Button
                  style={{
                    textTransform: "uppercase",
                    borderRadius: 40,
                    height: 40,
                    borderWidth: 1,
                    borderStyle: "solid",
                    width: "50%",
                    color: "#fff",
                  }}

                  sx={{
                    padding: '15px',
                    ':hover': {
                      bgcolor: '#006bb6',
                      color: '#fff',
                    },
                  }}

                  variant={props.btnType}
                >
                  Go To Features
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </a>
    </Grid>
  );
};



function TrainingCard(props) {
  return (
    <Grid item xs={12} className="basicCardContainer">
      <Card>
        <CardContent style={{ padding: 0, paddingRight: 15 }}>
          <Grid container xs={12} alignItems="center" height={100}>
            <Grid item xs={8}>
  
                <Typography
                          variant="body1"
                          sx={{paddingLeft: '25px'}}
                >
                          WIKI DOCUMENTS
                </Typography>
            
            </Grid>

            <Grid item xs={4} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{
                  padding: '15px',
                  ':hover': {
                    bgcolor: '#fff',
                    color: '#006bb6',
                  },
                }}
                href={
                  "https://tenjin.cdao.saicds.com/projects/ONBOARDING/wiki/4/Welcome%20To%20Tenjin"
                }
              >
                Learn More
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

function DatabricksCard(props) {
  return (
    <Grid item xs={12} className="basicCardContainer">
      <Card>
        <CardContent style={{ padding: 0, paddingRight: 15 }}>
          <Grid container xs={12} alignItems="center" height={100}>
            <Grid item xs={8}>
  
              <Typography variant="h3" >
                <img src={Databricks} />
              </Typography>
            
            </Grid>

            <Grid item xs={4} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{
                  padding: '15px',
                  ':hover': {
                    bgcolor: '#fff',
                    color: '#006bb6',
                  },
                }}
              >
                Learn More
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

const SearchCard = (props) => (
  <Grid container xs={12} className="searchCardContainer">
    <Typography
      variant="h1"
    >
      {props.title}
    </Typography>
    <Typography variant="p" className="searchCardBody">
      {props.body}
    </Typography>
    <Button 
      variant="contained" 
      href={props.link}
      sx={{
        padding: '15px',
        ':hover': {
          bgcolor: '#fff',
          color: '#006bb6',
        },
      }}
      >
      {props.btnTitle}
    </Button>
  </Grid>
);

export {
  DashboardCard,
  DatabricksCard,
  FeaturesCard,
  SearchCard,
  TrainingCard
}
