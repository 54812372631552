import React,{useEffect} from "react"
import { Grid,
          List,
          ListItem,
          ListItemText
} from "@mui/material/"
import Governance from "./content/governance"
import { ConfigContext } from "./config/config"

export default function GovernanceDashboard() {

  const configDetails = React.useContext(ConfigContext)

  useEffect((()=>{
    document.title = configDetails.data.map((item) => {return item.tabtitle})
  }))
  
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      spacing={3}
      className="governanceContainer" 
    >
  
      <Grid item xs={12} className="marginCenter">

          <Grid item xs={11}  >
            
             <List>
              <ListItem>
                <ListItemText 
                  primary={<span className="pageTitle" >Governance Dashboard</span>}
                  secondary={<span style={{paddingLeft: '30px'}}  >Security Adminstrator</span>}
                />
              </ListItem>
            </List>
         
       
          </Grid>

          <Grid item xs={11} style={{margin: '0 auto'}} >
          
            <Governance />
    
          </Grid>

      </Grid>

    </Grid>
  )
}
