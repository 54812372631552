import React,{useContext} from "react"
import { 
  Grid,
  Typography

} from "@mui/material"

import {
  ConfigContext,
  ConfigDispatchContext
} from "../config/config"

const Title = () => {

  const configDetails = React.useContext(ConfigContext)
  const setUserDetails = useContext(ConfigDispatchContext)

  return (

    <div>
      <Grid container xs={12} sm={12} md={12} lg={12}>
      
          <Grid item xs={11} >
            <Typography variant="body2" >
              Environment Instance: 
            </Typography>
            <Typography className="pageTitle" >
            {
              configDetails.data.map((item)=>{
                return <span>
                  {item.name}
                </span>
              })
             }
            </Typography>
          </Grid>
        

      </Grid>
    </div>
  )
}

export default Title
