import React from "react";
import { Grid, Typography } from "@mui/material";

import { SEARCH_ICON } from "../../assets/icons/IconList";

import { SearchCard } from "./cards";

const SearchSection = ({
  showDataCatalog = false,
  showModelRepository = false,
  showNlp = false,
}) => {
  return (
    <Grid container xs={12} spacing={2} style={{ height: "100%" }}>
      <Grid item container xs={12} alignItems={"center"}>
       
        <Typography variant="h4">
          Search & retrieve data for use in other applications
        </Typography>
      </Grid>
      <Grid item container xs={12} spacing={2}>
        {showDataCatalog && (
          <Grid item xs={4}>
            <SearchCard
              title="Data Catalog"
              body="Uploaded datasets and their descriptions, searchable by type"
              btnTitle="Begin Searching"
              link={"/dataCatalog"}
            />
          </Grid>
        )}
        {showModelRepository && (
          <Grid item xs={4}>
            <SearchCard
              title="Model Repository"
              body="Deployed and saved models, searchable by type"
              btnTitle="Begin Searching"
              link={"/modelRegistry"}
            />
          </Grid>
        )}
        {showNlp && (
          <Grid item xs={4}>
            <SearchCard
              title="Natural Language Search"
              body="Search through available datasets by keyphrase"
              btnTitle="Begin Searching"
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SearchSection;
