
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { List, ListItem, ListItemText, ListItemIcon, Avatar, ListItemAvatar } from "@mui/material/";
import { ConfigContext } from "../config/config"

export default function Tools() {

  const configDetails = React.useContext(ConfigContext);


  return (
    <Grid container xs={11} className="toolsPageContainer" >
       {/* AI/ML List */}
      <Grid container xs={12} spacing={3} >

      {configDetails.data.map((item) => {
          const toolSectionKeys = Object.keys(item.tools.sections)
          const allToolSections = toolSectionKeys.flatMap((key) => item.tools.sections[key])

        return(
          <Grid container  spacing={3} >
        
          <Grid item xs={4}>
              <Typography variant="h5" >
                {
                toolSectionKeys[0] === 'aiml' ? 'AI / ML' : ''
                }
              </Typography>
              <br/>
            </Grid>
         <Grid container xs={12} spacing={3} >
         
          {
            
            item.tools.sections.aiml.map((tools)=>{
          
              return  <Grid item xs={6}>
                    <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper', height: '150px', boxShadow: '#dcdcdc 1px 3px 2px 1px' }}>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar style={{width: '90px', height: '90px'}} ><img src={tools.image} style={{width: '100px'}} /></Avatar>
                          
                        </ListItemAvatar>
                        <ListItemText
                        style={{paddingLeft: '25px'}}
                          primary={<b>{tools.name}</b>}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {tools.description}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      </List>

                </Grid>
            })
            
          }
        </Grid>


       <Grid item xs={12} >
        <div className="spacer" />
       </Grid>


       <Grid item xs={4}>
              <Typography variant="h5" >
           
                {
                toolSectionKeys[1] === 'businessintelligence' ? 'Business Intelligence' : ''
                }
              </Typography>
             <br/>
            </Grid>
         <Grid container xs={12} spacing={3} >
         
          {
            
            item.tools.sections.businessintelligence.map((tools)=>{
          
              return  <Grid item xs={6}>
                <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper',height: '150px',boxShadow: '#dcdcdc 1px 3px 2px 1px'  }}>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar style={{width: '90px', height: '90px'}} ><img src={tools.image} style={{width: '100px'}} /></Avatar>
                          
                        </ListItemAvatar>
                        <ListItemText
                        style={{paddingLeft: '25px'}}
                          primary={<b>{tools.name}</b>}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {tools.description}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      </List>
           
                </Grid>
            })
            
          }
        </Grid>


       <Grid item xs={12} >
        <div className="spacer" />
       </Grid>

    
     <Grid item xs={4}>
              <Typography variant="h5" >
              {
                toolSectionKeys[2] === 'dataaccess' ? 'Data Access' : ''
                }
              </Typography>
              <br/>
            </Grid>
         <Grid container xs={12} spacing={3} >
         
          {
            
            item.tools.sections.dataaccess.map((tools)=>{
          
              return  <Grid item xs={6}>
                 <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper',height: '150px',boxShadow: '#dcdcdc 1px 3px 2px 1px'  }}>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar style={{width: '90px', height: '90px'}} ><img src={tools.image} style={{width: '100px'}} /></Avatar>
                          
                        </ListItemAvatar>
                        <ListItemText
                        style={{paddingLeft: '25px'}}
                          primary={<b>{tools.name}</b>}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {tools.description}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      </List>
                </Grid>
            })
            
          }
        </Grid>


       <Grid item xs={12} >
          <br/>
          <br/>
       </Grid>
            
     </Grid>


        )

      })}
 

    </Grid>

    </Grid>
  )
}

