import React from "react";
import {
  Grid,
  Typography,
  Button,
  Link,

} from "@mui/material"

import { APP_ICON } from "../../assets/icons/IconList";

import TableuLogo from "../../assets/images/tools/TableauLogo_square.png";

export const ToolsGrid = ({ tools = {} }) => {
  const toolSectionKeys = Object.keys(tools.sections);

  const allToolSections = toolSectionKeys.flatMap((key) => tools.sections[key]);

  return (
    <Grid container xs={12} className="toolsGridContainer" columnSpacing={2}>

        <Grid
          item
          container
  
        >
          <Button 
            variant="outlined"
            className="marginCenter"
            style={{
              textTransform: "uppercase",
              borderRadius: 40,
              height: 40,
              borderWidth: 1,
              borderStyle: "solid",
              width: "50%",
              color: "#006bb6",
            }}

            sx={{
              padding: '15px',
              ':hover': {
                bgcolor: '#dcdcdc',
                color: '#fff',
              },
            }}
            href={"/tools"}
          >
           <APP_ICON /> &nbsp; See All Our Tools
          </Button>
   
        </Grid>
   
      <Grid item xs={12} >
        <div className="spacer10" />
        {allToolSections.map((tool) => (
          <img
            key={tool.name}
            src={tool.image}
            style={{
              borderRadius: 10,
              height: "75px",
              marginLeft: 10,
              marginRight: 5,
              marginTop: '15px'
            }}
          />
        ))}
      </Grid>
    </Grid>
  );
};
export const ToolsList = () => (
  <Grid container xs={12} className="toolsList" spacing={3} >
    <Grid item xs={6} >
      <Link className="linkButton" href="https://tenjin.cdao.saicds.com/">Tenjin Portal</Link>
    </Grid>

    <Grid item xs={6} >
      <Link className="linkButton" href="/dataUpload">Data Upload</Link>
    </Grid>

    <Grid item xs={6} >
      <Link className="linkButton" href="/dataCatalog">Data Catalog</Link>
    </Grid>

    <Grid item xs={6} >
      <Link className="linkButton" href="/natLangSearch">NLP Search</Link>
    </Grid>

    <Grid item xs={6} >
      <Link className="linkButton" href="/modelRegistry">Model Registry</Link>
    </Grid>

    <Grid item xs={6} >
      <Link className="linkButton" href="/tools">Tools</Link>
    </Grid>

    <Grid item xs={6} >
      <Link className="linkButton"  href="/admin">Admin</Link>
    </Grid>

  </Grid>

);
