import React,{useContext} from "react"
import {
  Grid
} from "@mui/material/"
import {
  SettingsCard

} from "../components/admin/cards"

import {
  ConfigContext,
  ConfigDispatchContext
} from "../config/config"

export default function Admin() {

  const configDetails = React.useContext(ConfigContext)
  const setUserDetails = useContext(ConfigDispatchContext)

 
  return (
   
      <Grid container xs={11} className="adminContainer" spacing={3}>

        <Grid item xs={6} >
        {
          configDetails.data.map((item)=>{
            return <span>
              {item.admin.components.settings === true ? <SettingsCard /> : ""}
            </span>
          })
        }
        </Grid>

   

    </Grid>

  )
}
