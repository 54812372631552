import React,{useEffect} from "react"
import { 
  Container,
  List,
  ListItemText,
  Typography,
  ListItem,
} from '@mui/material'
import DemoFilter from './DemoFilter';
import { ConfigContext } from "../../../config/config"

function DemoListPage() {
  const configDetails = React.useContext(ConfigContext)

  useEffect((()=>{
    document.title = configDetails.data.map((item) => {return item.tabtitle})
  }))

  return (
      <main className='modelRegistryContainer' >
         <List >
              
                <ListItem>
          
                  <ListItemText
                    primary={<div className="pageDescription" >Deployed and saved models, searchable by type</div>}
                  />
                </ListItem>
              </List>
          <Container sx={{ mt: 5, mb: 5 }}>
             
           
            <DemoFilter/>
            <div className="spacer30" />
            <div  className="centerAlign" >
                <div className="spacer30" />
                <div className="spacer30" />
                <div className="spacer30" />
                  <Typography className="pageStamp" >
                     No models currently published
                  </Typography>
            </div>
                
            {/* <DemoList/> */}
          </Container>
      </main>
  );
}

export default DemoListPage;
