import React,{useEffect} from "react"
import { Grid,
          List,
          ListItem,
          ListItemText
} from "@mui/material/"
import ToolsContent from "./content/tools"
import { ConfigContext } from "./config/config"

export default function ToolsPage() {

  const configDetails = React.useContext(ConfigContext)

  useEffect((()=>{
    document.title = configDetails.data.map((item) => {return item.tabtitle})
  }))

  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      spacing={3}
      className="toolsPageContainer"
    >
  
      <Grid item xs={12} className="marginCenter">

          <Grid item xs={11}  >
            
          <List >
            <ListItem>
      
              <ListItemText
                primary={<div className="pageTitle" >All Available Tools</div>}
              />
            
            </ListItem>
          </List>
          <br/>
          <br/>
          </Grid>

          <Grid item xs={11} style={{margin: '0 auto'}} >
          
            <ToolsContent />
    
          </Grid>

      </Grid>

    </Grid>
  )
}
