import React,{useContext, useEffect} from "react"
import IconButton from "@mui/material/IconButton"
import {
  Menu,
  MenuItem
} from "@mui/material/"
import {
  MENU_ICON
} from "../assets/icons/IconList"
import {
  ConfigContext,
  ConfigDispatchContext
} from "../config/config"

const VIEW = process.env.REACT_APP_VIEW

const ITEM_HEIGHT = 75;

export default function PrimaryMenu() {

  const configDetails = React.useContext(ConfigContext)
  const setUserDetails = useContext(ConfigDispatchContext)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const url = window.location.href

  return (
    <div className="menuContainer">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{marginTop: '-10px'}}
      >
         {
                configDetails.data.map((item)=>{
                  return <MENU_ICON 
                  color={item.theme.menuIcon}
                  fontSize={"40px"}
                  />

          })}
     
      
      </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            className="menuItem"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        
          >
              {
                configDetails.data.map((item)=>{
                  return <span>
                   <MenuItem>
                    {
                      item.menu.links.dashboard === true ? <a  href="/" >Dashboard</a>  : ''
                    }
                   </MenuItem>
                   <MenuItem>
                    {
                      item.menu.links.admin === true ? <a href="/admin" >Admin</a>  : ''
                    }
                   
                   </MenuItem>
                   <MenuItem>
                    {
                      item.menu.links.modelregistry === true ? <a href="/modelRegistry" >Model Registry</a>  : ''
                    }</MenuItem>
                      <MenuItem>
                    {
                      item.menu.links.datacatalog === true ? <a  href="/dataCatalog" >Data Catalog</a>  : ''
                    }
                    </MenuItem>
                    <MenuItem>
                    {
                      item.menu.links.tools === true ? <a  href="/tools" >Tools</a>  : ''
                    }
                    </MenuItem>
                    <MenuItem>
                    {
                      item.menu.links.nlpsearch === true ? <a  href="/natLangSearch" >NLP Search</a>  : ''
                    }
                    </MenuItem>
                    <MenuItem>
                    {
                      item.menu.links.governance === true ? <a  href="/governance" >Governance</a>  : ''
                    }
                    </MenuItem>
                    <MenuItem>
                    {
                      item.menu.links.dataupload === true ? <a  href="/dataUpload" >Data Upload</a>  : ''
                    }
                    </MenuItem>
                  </span>
                })
              }
       </Menu>

    </div>
  )
}
