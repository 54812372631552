import React from "react"
import { Grid, Typography } from "@mui/material"
import { ConfigContext } from "../config/config"
import SAIC from "../assets/images/Saic-Logo-Blue.png"

const Footer = () => {

  const configDetails = React.useContext(ConfigContext)

  return (  
      <Grid container xs={12}  >
        <Grid item xs={10} >
          <div className="spacer30" />
          {configDetails.data.map((item) => {

            return <img src={SAIC} alt="SAIC Footer Logo" />
          
          })}
 
         </Grid>

         <Grid item xs={2} >
        
                {/* <Typography variant="body2"> Powered by SAIC | {new Date().getFullYear()}</Typography> */}
      
         </Grid>
      </Grid>

  )
}

export default Footer
