import * as React from "react";
import {
  Card,
  Chip,
  CardContent,
  Grid,
  Typography,
  IconButton,
  CardActionArea,
  CardMedia,
  Collapse
} from "@mui/material"
import { styled } from "@mui/material/styles"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  FormatDate
} from "../../utils/utilities"

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}))


function DataCatalogCard(props) {

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Grid item xs={11} className="dataCatalogContainer" >
    <Card style={{background: '#fff', borderRadius: '15px'}} >
      <CardMedia
          sx={{ height: 140 }}
          image={`${process.env.PUBLIC_URL}/images/6.jpeg`}
       
        />

      <CardContent >
        <Typography >
          
          <i style={{color: '#000', fontWeight: '500'}}><b>Date Created</b></i>
          &nbsp;&nbsp;
          <span style={{color: '#2f388e', fontSize: '16px'}} >
            {
              props.date === undefined ? '' :   <FormatDate 
              date={props.date}
            />
            }
           
          </span>
          
        </Typography>

    
        <Typography >
          <div className="spacer10" />
          <i style={{color: '#000', fontWeight: '500'}}><b>Requested By</b></i>
          &nbsp;&nbsp;
     
          {
            props.requestedBy === undefined ? <span style={{color: '#8a9be7' }} >Requester Unknown</span> : props.requestedBy
          }

        </Typography>

        <Typography >
          
          <i style={{color: '#000', fontWeight: '500'}}><b>Dataset Name</b></i>
          &nbsp;&nbsp;
          <span style={{color: '#2f388e', fontSize: '16px'}} >
            {
            props.name === undefined ? <span style={{color: '#8a9be7' }} >Name Unknown</span> : props.name.dataset.name
            }
          </span>
          
        </Typography>

        <Typography >
          
          <i style={{color: '#000', fontWeight: '500'}}><b>Dataset Owner</b></i>
          &nbsp;&nbsp;
          <span style={{color: '#2f388e', fontSize: '16px'}} >
            {
            props.owner === undefined ? <span style={{color: '#8a9be7' }} >Owner Unknown</span> : props.owner.dataset.owner
            }
          </span>
          
        </Typography>
        <div className="spacer20" />
        <Chip label="ADHOC" variant="outlined" style={{border: '1px solid #858fed', color: '#858fed'}}  />
            &nbsp;&nbsp;
        <Chip label="ADVANA" variant="outlined" style={{border: '1px solid #858fed', color: '#858fed'}} />
        <div className='spacer30' />
        <div className='divider' />
        <div className='spacer10' />
      
      </CardContent>
      <span>
      <CardActionArea   style={{margin: '0 auto', color: '#2d4098', textAlign: 'center',background: '#e9edff'}}   >
        <div className="spacer10" />
            <Typography><small style={{color: '#999'}} ><i>Learn More about</i></small> &nbsp;{props.name === undefined ? <span style={{color: '#8a9be7',textTransform: 'uppercase' }} >Name Unknown</span> : <span style={{textTransform: 'uppercase'}} >{props.name.dataset.name}</span>}</Typography>
          <ExpandMore
            style={{color: '#fff', margin: '5px auto', textAlign: 'center'}}
            className="expandMoreGradient" 
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon style={{width: '35px',height: '35px'}} />
          </ExpandMore>
      
      <div className='spacer10' />
      </CardActionArea>

      <Collapse in={expanded} timeout="auto" unmountOnExit style={{background: '#e9edff'}} >
        <CardContent >
        <div className='spacer30' />
        <Typography >
          
          <i style={{color: '#000', fontWeight: '500'}}><b>Date Updated</b></i>
          &nbsp;&nbsp;
          <span style={{color: '#2f388e', fontSize: '16px'}} >
       
             {
              props.date === undefined ? '' :   <FormatDate 
              date={props.updatedDate}
            />
            }

          </span>
          
        </Typography>
        <Typography >
          
          <i style={{color: '#000', fontWeight: '500'}}><b>Department Team</b></i>
          &nbsp;&nbsp;
          <span style={{color: '#2f388e', fontSize: '16px'}} >
          {props.team}
          </span>
          
        </Typography>
       
        <Typography >
          
          <i style={{color: '#000', fontWeight: '500'}}><b>Data Type</b></i>
          &nbsp;&nbsp;
          <span style={{color: '#2f388e', fontSize: '16px'}} >
          {props.type}
          </span>
          
        </Typography>

        <Typography >
          <div className="spacer10" />
          <i style={{color: '#000', fontWeight: '500'}}><b>Dataset Source</b></i>
          &nbsp;&nbsp;
          <br/>
          {
           props.source === undefined ? <span style={{color: '#8a9be7' }} >Source Unknown</span> : props.source.dataset.source
          }
         
        </Typography>

        <Typography >
         
         <i style={{color: '#000', fontWeight: '500'}}><b>Dataset File</b></i>
         &nbsp;&nbsp;
         <br/>
         <span style={{color: '#2f388e', fontSize: '16px'}} >
         {props.file}
         </span>
        </Typography>

        <div className="spacer20" />

        <Typography >
         
         <i style={{color: '#000', fontWeight: '500'}}><b>Dataset Description</b></i>
         &nbsp;&nbsp;
            {
            props.desc === undefined ? <span style={{color: '#8a9be7' }} >Description Unknown</span> : props.desc.dataset.description
            }
        </Typography>
        <div className="spacer20" />
    
        </CardContent>
      </Collapse>
      </span>

    </Card>
    </Grid>
  )
}


export { DataCatalogCard}
